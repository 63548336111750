import {
  FaAlignLeft,
  FaCalendar,
  FaLocationDot,
  FaUsers,
} from "react-icons/fa6";

import { cn } from "~/utils/cn";
import { InterviewerItem } from "./components/interviewer-item";
import {
  InterviewEventCardProps,
  InterviewEventCardViewMode,
} from "./interview-event-card.types";
import {
  formatDateInterviewEvent,
  formatTimeInterviewEvent,
  formatTimezone,
} from "./interview-event-card.utils";
import { SanitizedHtml } from "~/scalis-components/core";

export const InterviewEventCard = ({
  interview,
  className = "",
  viewMode = InterviewEventCardViewMode.Default,
}: InterviewEventCardProps) => {
  const eventStartDate = new Date(interview.startTime);
  const eventEndDate = new Date(interview.endTime);

  const formattedDate = formatDateInterviewEvent(eventStartDate);
  const formattedStartTime = formatTimeInterviewEvent(eventStartDate);
  const formattedEndTime = formatTimeInterviewEvent(eventEndDate);
  const formattedTimezone = formatTimezone(interview.timezone);
  return (
    <div
      className={cn(
        "flex w-full flex-col gap-3 rounded-xl border border-borders p-4",
        {
          "border-none": viewMode === InterviewEventCardViewMode.NoTitle,
        },
        className,
      )}
    >
      {viewMode === InterviewEventCardViewMode.Default && (
        <h2 className="text-sm font-semibold text-typography-high-contrast">
          {interview.title}
        </h2>
      )}

      <div className="flex gap-2">
        {viewMode === InterviewEventCardViewMode.NoTitle && (
          <FaCalendar className="text-base" />
        )}

        <div className="flex flex-col">
          <span className="text-sm text-typography-high-contrast">
            {`${formattedDate} ${formattedStartTime} - ${formattedEndTime}`}
          </span>
          <span className="text-sm text-typography-low-contrast">
            {formattedTimezone}
          </span>
          <span className="mt-2 text-xs italic text-typography-low-contrast">
            {`Scheduled by ${interview.user.full_name} • ${interview.nylasCalendar.name}`}
          </span>
        </div>
      </div>

      {interview.location && (
        <div className="flex items-center gap-2">
          <FaLocationDot className="text-base" />
          <span className="text-sm font-normal">{interview.location}</span>
        </div>
      )}

      {interview.internalHrAssociates &&
        interview.internalHrAssociates.length > 0 && (
          <div className="flex gap-2">
            <FaUsers className="text-base" />
            <div className="flex flex-col">
              <span className="text-sm font-normal text-typography-high-contrast">
                Interview Team
              </span>

              {interview?.internalHrAssociates?.map(interviewer => {
                return (
                  <InterviewerItem
                    key={interviewer.id}
                    interviewer={interviewer}
                  />
                );
              })}
            </div>
          </div>
        )}

      {interview.notes && (
        <div className="flex gap-2">
          <FaAlignLeft className="text-base" />
          <div className="flex flex-col">
            {viewMode === InterviewEventCardViewMode.NoTitle && (
              <span className="mb-4 text-sm font-normal text-typography-high-contrast">
                Details
              </span>
            )}

            <SanitizedHtml
              className="w-full text-sm"
              rawHtml={interview.notes}
            />
          </div>
        </div>
      )}
    </div>
  );
};
