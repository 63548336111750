import { MilestoneType } from "@prisma/client";
import { NameEnum } from "~/bff/graphql/generated/graphql";

export const MILESTONE_COLOR: { [key in MilestoneType]: string } = {
  SOURCING: "#E5C9FB",
  APPLICATION_REVIEW: "#C9D5FB",
  PRE_SCREEN: "#BDE9FD",
  CUSTOM: "#C8FBF6",
  OFFER: "#DFFFB1",
  HIRED: "#A6FBD0",
};

export const MILESTONE_COLOR_MAP: { [key in NameEnum]: string } = {
  SOURCING: "#E5C9FB",
  APPLICATION_REVIEW: "#C9D5FB",
  PRE_SCREEN: "#BDE9FD",
  INTERVIEWING: "#C8FBF6",
  OFFER: "#DFFFB1",
  HIRED: "#A6FBD0",
};

export const INTERVIEW_COLOR_MAP: { [key: string]: string } = {
  INTERVIEW_NOT_SCHEDULED: "bg-[#D7DAE2]",
  INTERVIEW_REQUESTED: "bg-[#F6B67B]",
  INTERVIEW_SCHEDULED: "bg-[#E9E584]",
  INTERVIEW_COMPLETED: "bg-[#8BE0A3]",
  REJECTED: "bg-[#FFB3B3]",
};
